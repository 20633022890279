import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.inter = setInterval(() => {
      fetch('/pre_alert', {
        headers: {
          'Accept': 'text/vnd.turbo-stream.html'
        }
      }).then(response => response.text())
        .then(html => {
          document.body.insertAdjacentHTML('beforeend', html); // 插入Turbo Stream指令
        });
    },1200000);

  }

  disconnect() {
    clearInterval(this.inter)
  }
}
